// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-rug-page-template-tsx": () => import("/home/ubuntu/gatsby/src/templates/RugPageTemplate.tsx" /* webpackChunkName: "component---src-templates-rug-page-template-tsx" */),
  "component---src-templates-content-page-template-tsx": () => import("/home/ubuntu/gatsby/src/templates/ContentPageTemplate.tsx" /* webpackChunkName: "component---src-templates-content-page-template-tsx" */),
  "component---src-templates-collection-page-template-tsx": () => import("/home/ubuntu/gatsby/src/templates/CollectionPageTemplate.tsx" /* webpackChunkName: "component---src-templates-collection-page-template-tsx" */),
  "component---src-templates-showroom-page-template-tsx": () => import("/home/ubuntu/gatsby/src/templates/ShowroomPageTemplate.tsx" /* webpackChunkName: "component---src-templates-showroom-page-template-tsx" */),
  "component---src-templates-rug-motherpiece-page-template-tsx": () => import("/home/ubuntu/gatsby/src/templates/RugMotherpiecePageTemplate.tsx" /* webpackChunkName: "component---src-templates-rug-motherpiece-page-template-tsx" */),
  "component---src-templates-collection-overview-page-template-tsx": () => import("/home/ubuntu/gatsby/src/templates/CollectionOverviewPageTemplate.tsx" /* webpackChunkName: "component---src-templates-collection-overview-page-template-tsx" */),
  "component---src-pages-404-tsx": () => import("/home/ubuntu/gatsby/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-imprint-tsx": () => import("/home/ubuntu/gatsby/src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/ubuntu/gatsby/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-de-tsx": () => import("/home/ubuntu/gatsby/src/pages/privacy-policy-de.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-de-tsx" */),
  "component---src-pages-privacy-policy-en-tsx": () => import("/home/ubuntu/gatsby/src/pages/privacy-policy-en.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-en-tsx" */),
  "component---src-pages-search-tsx": () => import("/home/ubuntu/gatsby/src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */)
}

