module.exports = [{
      plugin: require('/home/ubuntu/gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-619538-16","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":10},
    },{
      plugin: require('/home/ubuntu/gatsby/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#ebedf2","display":"minimal-ui","icon":"static/img/favicon.png","lang":"en","name":"JAN KATH – contemporary rug art.","short_name":"Jan Kath","start_url":"/","theme_color":"#ff0042"},
    },{
      plugin: require('/home/ubuntu/gatsby/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"FF0040","showSpinner":false},
    },{
      plugin: require('/home/ubuntu/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
