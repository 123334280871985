require('intersection-observer');
require('url-search-params-polyfill');

const smoothscroll = require('smoothscroll-polyfill');

// This sets `windows.previousPath` to the last location. This is used for back buttons via `useGetPreviousUrl`.
exports.onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer];

  locations.push(window.location.href);

  window.previousPath = locations[locations.length - 2];
};

exports.onClientEntry = () => {
  smoothscroll.polyfill();

  // Honor Google Analytics Opt Out
  const disableString = `ga-disable-${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`;

  if (typeof window !== 'undefined') {
    if (document.cookie.indexOf(disableString + '=true') > -1) {
      window[disableString] = true;
    }
  }
};
